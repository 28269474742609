<template>
  <section id="classroom">
    <b-row class="mb-2" v-if="isAdmin()">
      <!-- Navigation Tab -->
      <b-col lg="12">
        <b-button
          class="float-right"
          variant="primary"
          :to="{
            name: checkAuthorizeRole('trainings.create'),
          }"
        ><i class="ti ti-plus small-gap-right" /> Tambah Pelatihan</b-button>
      </b-col>
    </b-row>

    <!-- Loading Classroom List-->
    <b-row v-if="isLoading">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner
            variant="primary"
            class="mr-1"
          />
          <p class="mt-1">
            Memuat Daftar Kelas
          </p>
        </div>
      </b-col>
    </b-row>

    <!-- Classroom List -->
    <b-row v-if="!isLoading && filteredClassroom.length > 0">
      <b-col
        v-for="classroom in filteredClassroom"
        :key="classroom.id"
        xl="4"
        lg="6"
        md="6"
      >
        <class-card
          :is-joined="false"
          :is-teacher="true"
          :classroom-data="classroom"
          @trigger-modal="triggerModal"
        />
      </b-col>

      <!-- Pagination -->
      <b-col
        sm="12"
        class="d-flex justify-content-end"
      >
        <b-pagination
          v-model="filter.current_page"
          :total-rows="filter.total"
          :per-page="filter.per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @change="handlePageChange"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Classroom Empty State -->
    <b-row v-if="!isLoading && filteredClassroom.length === 0">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center pt-2">
          <b-img
            class="illustration-small"
            fluid
            :src="emptyState"
          />
          <p class="mb-2">
            Kelas tidak ditemukan
          </p>
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
// *Utils
import client from '@/libs/http/axios-config'
import store from '@/store'
import { checkAuthorizeRole, isAdmin } from '@/auth/utils'

// *Components
import {
  BRow,
  BCol,
  BTabs,
  BNavItem,
  BSpinner,
  BButton,
  BImg,
  BPagination,
} from 'bootstrap-vue'
import ClassCard from '@core/layouts/components/card/class-card/ClassCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BNavItem,
    ClassCard,
    BSpinner,
    BButton,
    BImg,
    BPagination,
  },
  data() {
    return {
      // State
      isLoading: true,
      currentTab: 0,
      navTabs: ['Kelas Diampu'],
      emptyState: require('@/assets/images/illustration/empty-classroom-state.svg'),

      // Data
      selectedClassroom: {},
      allClassrooms: [],
      takenClassrooms: [],
      userId: '',

      // Filter
      filter: {
        search: '',
      },
    }
  },
  computed: {
    filteredClassroom() {
      const tempClassrooms = this.takenClassrooms
      return tempClassrooms.data.filter(classroom => {
        return classroom.isTaken
      })
    },
    searchQuery() {
      return store.state.search.searchQuery
    },
  },
  watch: {
    searchQuery() {
      this.filter.search = this.searchQuery
      this.handlePageChange(1)
    },
  },
  async created() {
    const config = {
      params: {
        search: this.searchQuery,
        page: 1,
      },
    }

    // get user id
    this.userId = JSON.parse(localStorage.getItem('userData')).id

    // Get taken classroom
    await this.fetchTakenClassrooms(config)

    // Set isLoading to false
    this.isLoading = false
  },
  mounted() {
  },
  methods: {
    isAdmin,
    triggerModal(subjectData) {
      this.selectedClassroom = subjectData
      this.$bvModal.show('enroll-modal')
    },
    checkAuthorizeRole,

    // fetch content by nav tab
    async fetchTakenClassrooms(config) {
      config.params.per_page = 9

      await client.get('/api/admin/trainings', config).then(response => {
        this.takenClassrooms = response.data.data

        // Set total data
        this.filter.total = response.data.data.total
        this.filter.per_page = response.data.data.per_page
        this.filter.current_page = response.data.data.current_page
      })

      // Mapping Classroom
      this.mappingTakenClassroom()
    },

    // mapping taken classroom
    mappingTakenClassroom() {
      // mapping all classroom
      this.takenClassrooms.data.map(classroom => {
        classroom.isTaken = true
      })
    },

    // handle nav tab
    changeNavTab(tabIndex) {
      this.currentTab = tabIndex
      this.handlePageChange(1)
    },

    async handlePageChange(page) {
      this.isLoading = true

      const config = {
        params: {
          search: this.filter.search,
          page,
        },
      }

      await this.fetchTakenClassrooms(config)

      this.isLoading = false
    },

    // breakdown object if too long
    decomposeObject(object) {
      return JSON.parse(JSON.stringify(object))
    },
  },
}
</script>

<style lang="scss">
.small-gap-right {
  margin-right: 8px;
}

.subject-icon {
  font-size: 2rem;
}

#enroll-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}

.illustration-small {
  height: 300px;
  object-fit: cover;
}
</style>
